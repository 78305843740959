<template>
  <AppLayout>
    <main>
      <div
        class="
          page-header
          pb-10
          page-header-dark
          bg-gradient-primary-to-secondary
        "
      >
        <div class="container-fluid">
          <div class="page-header-content">
            <h1 class="page-header-title">
              <div class="page-header-icon"><i data-feather="filter"></i></div>
              <span>Conveyance</span>
            </h1>
            <div class="page-header-subtitle ml-2">
              Update Conveyance Details
            </div>
          </div>
        </div>
      </div>
      <div class="container-fluid mt-n10">
        <div class="card mb-4">
          <div class="card-header">Conveyance</div>
          <div class="card-body">
            <div v-if="pageLoading">
              <img
                style="width: 30%"
                class="d-block mx-auto"
                src="@/assets/loader.gif"
              />
            </div>
            <div v-else class="form">
              <form @submit.prevent="handleSubmit()">
                <div class="form-group">
                  <label for="name">Conveyance Name</label
                  ><input
                    class="form-control"
                    id="name"
                    type="text"
                    v-model="conveyance.name"
                  />
                </div>

                <button v-if="loading" class="btn btn-primary disabled">
                  <div
                    class="spinner-border spinner-border-sm mx-1 text-light"
                    role="status"
                  ></div>
                  Loading...
                </button>
                <button v-else type="submit" class="btn btn-primary">
                  Update Conveyance
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </main>
  </AppLayout>
</template>


<script>
import AppLayout from "@/layouts/AppLayout.vue";
import { mapState, mapActions } from "pinia";
import { useAdminStore } from "@/stores/admin";

export default {
  name: "Update Conveyance",
  components: { AppLayout },
  data() {
    return {};
  },
  computed: {
    ...mapState(useAdminStore, ["loading", "pageLoading", "conveyance"]),
  },

  methods: {
    ...mapActions(useAdminStore, ["updateConveyance", "getConveyance"]),
    async handleSubmit() {
      const res = await this.updateConveyance(this.conveyance);
      if (res == "success") {
        this.$router.push("/conveyance");
      }
    },
  },

  created() {
    this.getConveyance(this.$route.params.id);
  },
};
</script>